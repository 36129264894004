import { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";

import useResponsiveWithHydrationFix from "../../hooks/useResponsiveWithHydrationFix";
import useDeviceDetect from "../../hooks/useDeviceDetect";

import { ALRadioNavigation } from "../../components/ALComponents";

import * as Styles from "./membershipActions/styles.module.scss";

function MembershipActions() {
  const { isMobile } = useDeviceDetect();

  const subscriptionActionsImage = useResponsiveWithHydrationFix(
    <StaticImage
      src="../../images/subscription/luxe/subscription_actions_image_mobile.jpg"
      alt="Pause"
    />,
    <StaticImage
      src="../../images/subscription/luxe/subscription_actions_image_desktop.jpg"
      alt="Pause"
    />
  );

  const [activeTab, setActiveTab] = useState("pause");

  const navigationElements = [
    { key: "pause", content: "Pause" },
    { key: "skip", content: "Skip" },
    { key: "cancel", content: "Cancel" },
  ];

  return (
    <div className={Styles.membershipActions}>
      <div className={Styles.membershipActions__image}>{subscriptionActionsImage}</div>
      <div className={Styles.membershipActions__content}>
        <h2 className="h2 h2--bold h2--uc">Pause, skip, cancel at anytime</h2>
        <ALRadioNavigation
          selectedKey={activeTab}
          elements={navigationElements}
          onClick={setActiveTab}
          isExpandable={isMobile}
          className="membership-actions"
        />
        <div className={Styles.membershipActions__content_actions}>
          <div className={activeTab === "pause" ? "" : "hidden"}>
            <p>
              Press pause for up to 3 months - you'll be charged again as soon as you're ready to
              restart.
            </p>
          </div>
          <div className={activeTab === "skip" ? "" : "hidden"}>
            <p>
              Need a quick break? Skip one month and you'll automatically restart the next month.
            </p>
          </div>
          <div className={activeTab === "cancel" ? "" : "hidden"}>
            <p>
              We'll miss you but understand some things don't last forever. Just be sure to cancel
              24 hours before your next charge.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MembershipActions;
