// extracted by mini-css-extract-plugin
export var luxe = "styles-module--luxe--a3f92";
export var textImage__button = "styles-module--text-image__button--9ab4c";
export var textImage__buttons = "styles-module--text-image__buttons--361cc";
export var textImage__content = "styles-module--text-image__content--d9113";
export var textImage__description = "styles-module--text-image__description--fb177";
export var textImage__image = "styles-module--text-image__image--1f439";
export var textImage__imageLazyload = "styles-module--text-image__image-lazyload--7bcd0";
export var textImage__title = "styles-module--text-image__title--0197f";
export var textImage__video = "styles-module--text-image__video--22a1b";
export var textImage__videoContainer = "styles-module--text-image__video-container--9a0b1";
export var textImage__videoThumbnail = "styles-module--text-image__video-thumbnail--d13a0";
export var textImage__videoVimeo = "styles-module--text-image__video-vimeo--7d85c";
export var textImage__vimeo = "styles-module--text-image__vimeo--1ac3b";
export var textImage__wrapper = "styles-module--text-image__wrapper--11124";
export var textImage__wrapperReverse = "styles-module--text-image__wrapper-reverse--e4b44";