import { useContext } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { navigate } from "gatsby";
import { Helmet } from "react-helmet";

import { LuxeContext } from "../context/LuxeProvider";
import { isBrowser } from "../context/helpers";

import useResponsiveWithHydrationFix from "../hooks/useResponsiveWithHydrationFix";

import GetOnTheListImageMobile from "../images/subscription/luxe/get_on_the_list_image_mobile.jpg";
import GetOnTheListImageDesktop from "../images/subscription/luxe/get_on_the_list_image_desktop.jpg";

import COLORS from "../constants/Colors";
import { ComponentType } from "../constants/ComponentType";
import TextImage from "../components/blocks/TextImage";
import MembershipActions from "./luxe/MembershipActions";
import PriceComparisons from "./luxe/PriceComparisons";
import FaqLuxe from "../components/FaqLuxe";

import * as Styles from "./luxe/styles.module.scss";

function Luxe() {
  const { luxe } = useContext(LuxeContext);

  const headerImage = useResponsiveWithHydrationFix(
    <StaticImage src="../images/subscription/luxe/header_image_mobile.jpg" alt="Header" />,
    <StaticImage src="../images/subscription/luxe/header_image_desktop.jpg" alt="Header" />
  );
  const benefitsImage = useResponsiveWithHydrationFix(
    <StaticImage src="../images/subscription/luxe/benefits_image_mobile.jpg" alt="Benefits" />,
    <StaticImage src="../images/subscription/luxe/benefits_image_desktop.jpg" alt="Benefits" />
  );
  const perksImage = useResponsiveWithHydrationFix(
    <StaticImage src="../images/subscription/luxe/perks_image_mobile.jpg" alt="Perks" />,
    <StaticImage src="../images/subscription/luxe/perks_image_desktop.jpg" alt="Perks" />
  );

  const getOnTheListContent = {
    title: "Get on the luxe list",
    description:
      "Becoming Luxe is simple! Just sign up when prompted at checkout. You'll be able to pause, skip, or cancel at any time. It really is that easy.",
    image: {
      altText: "Get on the list",
      mobileImage: {
        file: {
          url: GetOnTheListImageMobile,
        },
      },
      desktopImage: {
        file: {
          url: GetOnTheListImageDesktop,
        },
      },
    },
    imagevideoPosition: "left",
    backgroundColor: COLORS.OAT,
    textColors: {
      mobile: COLORS.BLACK,
      desktop: COLORS.BLACK,
    },
    links: [
      {
        title: "START SHOPPING",
        link: {
          link: "/collections/bestsellers/",
        },
      },
    ],
  };

  if (!luxe.isLuxeEnabled) {
    if (isBrowser) {
      navigate("/");
    }
    return null;
  }

  return (
    <div>
      <Helmet
        title="Jewelry Loyalty Program | Ana Luisa Luxe"
        meta={[
          {
            name: "description",
            content:
              "Join Ana Luisa's Luxe for $39.99/month! Earn credits, enjoy elegant jewelry, and experience shopping freedom at your fingertips. Discover more now!",
          },
        ]}
      />
      {headerImage}
      {benefitsImage}
      <TextImage content={getOnTheListContent} componentType={ComponentType.LUXE} />
      {perksImage}
      <PriceComparisons />
      <MembershipActions />
      <div className={Styles.faq}>
        <FaqLuxe />
      </div>
    </div>
  );
}

export default Luxe;
